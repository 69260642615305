import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

interface PrivacyModalProps {
  modalIsOpen: boolean;
  onModalClose: () => void;
}

const PrivacyModal = (props: PrivacyModalProps) => {
  return (
    <Modal show={props.modalIsOpen} onHide={props.onModalClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Privacy Policy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <h6>Last updated October 17, 2023</h6>
          <br />
          <p>
            This privacy notice for Globalbuzzers PR ("we," "us," or "our"),
            describes how and why we might collect, store, use, and/or share
            ("process") your information when you use our services ("Services"),
            such as when you:
          </p>
          <p>
            - Visit our website at{' '}
            <a href="https://globalbuzzers.com">https://globalbuzzers.com</a>,
            or any website of ours that links to this privacy notice. <br /> -
            Engage with us in other related ways, including any sales,
            marketing, or events.
          </p>
          <p>
            <span style={{ fontWeight: '700' }}>Questions or concerns? </span>
            Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and
            practices, please do not use our Services. If you still have any
            questions or concerns, please contact us at info@globalbuzzers.com
          </p>
          <br />
          <h6>SUMMARY OF KEY POINTS</h6>
          <br />
          <p>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by using our
            table of contents below to find the section you are looking for.
          </p>
          <br />
          <p>
            <span style={{ fontWeight: '700' }}>
              What personal information do we process?{' '}
            </span>
            When you visit, use, or navigate our Services, we may process
            personal information depending on how you interact with us and the
            Services, the choices you make, and the products and features you
            use.
          </p>
          <p>
            <span style={{ fontWeight: '700' }}>
              Do we process any sensitive personal information?{' '}
            </span>
            We do not process sensitive personal information.
          </p>
          <p>
            <span style={{ fontWeight: '700' }}>
              Do we receive any information from third parties?{' '}
            </span>
            We do not receive any information from third parties.
          </p>
          <p>
            <span style={{ fontWeight: '700' }}>
              How do we process your information?{' '}
            </span>
            We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention,
            and to comply with law. We may also process your information for
            other purposes with your consent. We process your information only
            when we have a valid legal reason to do so.
          </p>
          <p>
            <span style={{ fontWeight: '700' }}>
              In what situations and with which types of parties do we share
              personal information?{' '}
            </span>
            We may share information in specific situations and with specific
            categories of third parties.
          </p>
          <p>
            <span style={{ fontWeight: '700' }}>
              How do we keep your information safe?{' '}
            </span>
            We have organizational and technical processes and procedures in
            place to protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information.
          </p>
          <p>
            <span style={{ fontWeight: '700' }}>What are your rights? </span>
            Depending on where you are located geographically, the applicable
            privacy law may mean you have certain rights regarding your personal
            information.
          </p>
          <p>
            <span style={{ fontWeight: '700' }}>
              How do you exercise your rights?{' '}
            </span>
            The easiest way to exercise your rights is by visiting 
            <a href="https://globalbuzzers.com">https://globalbuzzers.com</a>,
            or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>
          <br />
          <h6>
            Want to learn more about what we do with any information we collect?
          </h6>
          <br />
          <h5>1. What information do we collect?</h5>
          <br />
          <p>
            Personal information you disclose to us
            <br />
            In Short: We collect personal information that you provide to us.
            <br />
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
            <br />
            Personal Information Provided by You. The personal information that
            we collect depends on the context of your interactions with us and
            the Services, the choices you make, and the products and features
            you use. The personal information we collect may include the
            following:
            <br />
            <ul style={{ listStyle: 'inside' }}>
              <li>names</li>
              <li>email addresses</li>
              <li>usernames</li>
              <li>passwords</li>
              <li>debit/credit card numbers</li>
              <li>mailing addresses</li>
              <li>phone numbers</li>
              <li>contact preferences</li>
              <li>job titles</li>
              <li>billing addresses</li>
              <li>contact or authentication data</li>
            </ul>
            <span style={{ fontWeight: '700' }}>Sensitive Information. </span>We
            do not process sensitive information.
            <br />
            <span style={{ fontWeight: '700' }}>Payment Data. </span>We may
            collect data necessary to process your payment if you make
            purchases, such as your payment instrument number, and the security
            code associated with your payment instrument. All payment data is
            stored by Stripe. You may find their privacy notice link(s) here:
            https://stripe.com/privacy
            <br />
            In order to process payments made through our website, we utilize
            the services of Stripe, a reputable payment processor. Stripe's
            privacy policy governs the handling of your payment data when you
            use our services.
            <br />
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <br />
          <p>Information automatically collected</p>
          <br />
          <p>
            In Short: Some information — such as your Internet Protocol (IP)
            address and/or browser and device characteristics — is collected
            automatically when you visit our Services.
            <br />
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
            <br />
            Like many businesses, we also collect information through cookies
            and similar technologies.
            <br />
            The information we collect includes:
            <ul style={{ listStyle: 'inside' }}>
              <li>
                Log and Usage Data. Log and usage data is service-related,
                diagnostic, usage, and performance information our servers
                automatically collect when you access or use our Services and
                which we record in log files. Depending on how you interact with
                us, this log data may include your IP address, device
                information, browser type, and settings and information about
                your activity in the Services (such as the date/time stamps
                associated with your usage, pages and files viewed, searches,
                and other actions you take such as which features you use),
                device event information (such as system activity, error reports
                (sometimes called "crash dumps"), and hardware settings).
              </li>
              <li>
                Device Data. We collect device data such as information about
                your computer, phone, tablet, or other device you use to access
                the Services. Depending on the device used, this device data may
                include information such as your IP address (or proxy server),
                device and application identification numbers, location, browser
                type, hardware model, Internet service provider and/or mobile
                carrier, operating system, and system configuration information.
              </li>
              <li>
                Location Data. We collect location data such as information
                about your device's location, which can be either precise or
                imprecise. How much information we collect depends on the type
                and settings of the device you use to access the Services. For
                example, we may use GPS and other technologies to collect
                geolocation data that tells us your current location (based on
                your IP address). You can opt out of allowing us to collect this
                information either by refusing access to the information or by
                disabling your Location setting on your device. However, if you
                choose to opt out, you may not be able to use certain aspects of
                the Services
              </li>
            </ul>
          </p>
          <br />
          <h5>2. How do we process your information?</h5>
          <br />
          <p>
            In Short: We process your information to provide, improve, and
            administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
            <br />
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
            <ul style={{ listStyle: 'inside' }}>
              <li>
                To facilitate account creation and authentication and otherwise
                manage user accounts - We may process your information so you
                can create and log in to your account, as well as keep your
                account in working order.
              </li>
              <li>
                To deliver and facilitate delivery of services to the user - We
                may process your information to provide you with the requested
                service.
              </li>
              <li>
                To respond to user inquiries/offer support to users - We may
                process your information to respond to your inquiries and solve
                any potential issues you might have with the requested service.
              </li>
              <li>
                To send administrative information to you - We may process your
                information to send you details about our products and services,
                changes to our terms and policies, and other similar
                information.
              </li>
              <li>
                To fulfill and manage your orders - We may process your
                information to fulfill and manage your orders, payments,
                returns, and exchanges made through the Services.
              </li>
              <li>
                To enable user-to-user communications - We may process your
                information if you choose to use any of our offerings that allow
                for communication with another user.
              </li>
              <li>
                To request feedback - We may process your information when
                necessary to request feedback and to contact you about your use
                of our Services.
              </li>
              <li>
                To send you marketing and promotional communications - We may
                process the personal information you send to us for our
                marketing purposes, if this is in accordance with your marketing
                preferences. You can opt out of our marketing emails at any
                time.
              </li>
              <li>
                To save or protect an individual's vital interest - We may
                process your information when necessary to save or protect an
                individual’s vital interest, such as to prevent harm.
              </li>
            </ul>
          </p>
          <br />
          <h5>
            3. What legal bases do we rely on to process your information?
          </h5>
          <br />
          <p>
            In Short: We only process your personal information when we believe
            it is necessary and we have a valid legal reason (i.e., legal basis)
            to do so under applicable law, like with your consent, to comply
            with laws, to provide you with services to enter into or fulfill our
            contractual obligations, to protect your rights, or to fulfill our
            legitimate business interests.
            <br />
            If you are located in the EU or UK, this section applies to you.
            <br />
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
            <br />
            - Consent. We may process your information if you have given us
            permission (i.e., consent) to use your personal information for a
            specific purpose. You can withdraw your consent at any time.
            <br />
            - Performance of a Contract. We may process your personal
            information when we believe it is necessary to fulfill our
            contractual obligations to you, including providing our Services or
            at your request prior to entering into a contract with you.
            <br />- Legitimate Interests. We may process your information when
            we believe it is reasonably necessary to achieve our legitimate
            business interests and those interests do not outweigh your
            interests and fundamental rights and freedoms. For example, we may
            process your personal information for some of the purposes described
            in order to:
            <ul style={{ listStyle: 'inside' }}>
              <li>
                Send users information about special offers and discounts on our
                products and services
              </li>
              <li>
                Understand how our users use our products and services so we can
                improve user experience
              </li>
            </ul>
            - Legal Obligations. We may process your information where we
            believe it is necessary for compliance with our legal obligations,
            such as to cooperate with a law enforcement body or regulatory
            agency, exercise or defend our legal rights, or disclose your
            information as evidence in litigation in which we are involved.
            <br />
            - Vital Interests. We may process your information where we believe
            it is necessary to protect your vital interests or the vital
            interests of a third party, such as situations involving potential
            threats to the safety of any person.
            <br />
            If you are located in Canada, this section applies to you.
            <br />
            We may process your information if you have given us specific
            permission (i.e., express consent) to use your personal information
            for a specific purpose, or in situations where your permission can
            be inferred (i.e., implied consent). You can withdraw your consent
            at any time.
            <br />
            In some exceptional cases, we may be legally permitted under
            applicable law to process your information without your consent,
            including, for example:
            <br />
            - If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way
            <br />
            - For investigations and fraud detection and prevention
            <br />
            - For business transactions provided certain conditions are met
            <br />
            - If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim For
            identifying injured, ill, or deceased persons and communicating with
            next of kin
            <br />
            - If we have reasonable grounds to believe an individual has been,
            is, or may be victim of financial abuse
            <br />
            - If it is reasonable to expect collection and use with consent
            would compromise the availability or the accuracy of the information
            and the collection is reasonable for purposes related to
            investigating a breach of an agreement or a contravention of the
            laws of Canada or a province
            <br />
            - If disclosure is required to comply with a subpoena, warrant,
            court order, or rules of the court relating to the production of
            records
            <br />
            - If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
            <br />
            - If the collection is solely for journalistic, artistic, or
            literary purposes
            <br />- If the information is publicly available and is specified by
            the regulations
          </p>
          <br />
          <h5>4. When and with whom do we share your personal information?</h5>
          <br />
          <p>
            In Short: We may share information in specific situations described
            in this section and/or with the following categories of third
            parties.
            <br />- Vendors, Consultants, and Other Third-Party Service
            Providers. We may share your data with third-party vendors, service
            providers, contractors, or agents ("third parties") who perform
            services for us or on our behalf and require access to such
            information to do that work. We have contracts in place with our
            third parties, which are designed to help safeguard your personal
            information. This means that they cannot do anything with your
            personal information unless we have instructed them to do it. They
            will also not share your personal information with any organization
            apart from us. They also commit to protect the data they hold on our
            behalf and to retain it for the period we instruct. The categories
            of third parties we may share personal information with are as
            follows:
            <ul>
              <li>Data Analytics Services</li>
              <li>Ad Networks</li>
              <li>Affiliate Marketing Programs</li>
              <li>Communication & Collaboration Tools</li>
              <li>Payment Processors</li>
              <li>Website Hosting Service Providers</li>
              <li>
                We also may need to share your personal information in the
                following situations:
              </li>
            </ul>
            - Business Transfers. We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
            <br />- Other Users. When you share personal information (for
            example, by posting comments, contributions, or other content to the
            Services) or otherwise interact with public areas of the Services,
            such personal information may be viewed by all users and may be
            publicly made available outside the Services in perpetuity.
            Similarly, other users will be able to view descriptions of your
            activity, communicate with you within our Services, and view your
            profile.
          </p>
          <br />
          <h5>5. Do we use cookies and other tracking technologies?</h5>
          <br />
          <p>
            In Short: We may use cookies and other tracking technologies to
            collect and store your information.
            <br />
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information.
          </p>
          <br />
          <h5>6. How long do we keep your information?</h5>
          <br />
          <p>
            In Short: We keep your information for as long as necessary to
            fulfill the purposes outlined in this privacy notice unless
            otherwise required by law
            <br />
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
            <br />
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
          <br />
          <h5>7. How do we keep your information safe?</h5>
          <br />
          <p>
            In Short: We aim to protect your personal information through a
            system of organizational and technical security measures.
            <br />
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
          <br />
          <h5>8. Do we collect information from minors?</h5>
          <br />
          <p>
            In Short: We do not knowingly collect data from or market to
            children under 18 years of age.
            <br />
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at info@globalbuzzers.com.
          </p>
          <br />
          <h5>9. What are your privacy rights?</h5>
          <br />
          <p>
            In Short: In some regions, such as the European Economic Area (EEA),
            United Kingdom (UK), Switzerland, and Canada, you have rights that
            allow you greater access to and control over your personal
            information. You may review, change, or terminate your account at
            any time.
            <br />
            In some regions (like the EEA, UK, Switzerland, and Canada), you
            have certain rights under applicable data protection laws. These may
            include the right (i) to request access and obtain a copy of your
            personal information, (ii) to request rectification or erasure;
            (iii) to restrict the processing of your personal information; (iv)
            if applicable, to data portability; and (v) not to be subject to
            automated decision-making. In certain circumstances, you may also
            have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact box.
            <br />
            We will consider and act upon any request in accordance with
            applicable data protection laws.
            <br />
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your Member State data protection authority or
            UK data protection authority.
            <br />
            If you are located in Switzerland, you may contact the Federal Data
            Protection and Information Commissioner.
            <br />
            - Withdrawing your consent: If we are relying on your consent to
            process your personal information, which may be express and/or
            implied consent depending on the applicable law, you have the right
            to withdraw your consent at any time. You can withdraw your consent
            at any time by contacting us by using the contact details provided
            in the contact us section below or updating your preferences.
            <br />
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
            <br />
            - Opting out of marketing and promotional communications:
            <br />
            You can unsubscribe from our marketing and promotional
            communications at any time by clicking on the unsubscribe link in
            the emails that we send, or by contacting us using the details
            provided in the contact us section. You will then be removed from
            the marketing lists. However, we may still communicate with you —
            for example, to send you service-related messages that are necessary
            for the administration and use of your account, to respond to
            service requests, or for other non-marketing purposes.
            <br />
            - Account Information
            <br />
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
            <br />
            Log in to your account settings and update your user account.
            <br />
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
            <br />
            If you have questions or comments about your privacy rights, you may
            email us at info@globalbuzzers.com
          </p>
          <br />
          <h5>10. Controls for do-not-track features</h5>
          <br />
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
          <br />
          <h5>11. Do United States residents have specific privacy rights?</h5>
          <br />
          <p>
            - In Short: If you are a resident of California, Colorado,
            Connecticut, Utah or Virginia, you are granted specific rights
            regarding access to your personal information.
            <br />
            What categories of personal information do we collect?
            <br />
            We have collected the following categories of personal information
            in the past twelve (12) months:
            <br />
            A. Identifiers - Contact details, such as real name, alias, postal
            address, telephone or mobile contact number, unique personal
            identifier, online identifier, Internet Protocol address, email
            address, and account NAME - COLLECTED
            <br />
            B. Personal information as defined in the California Customer
            Records statute - Name, contact information, education, employment,
            employment history, and financial information - COLLECTED
            <br />
            C. Protected classification characteristics under state or federal
            law - Gender and date of birth - NOT COLLECTED
            <br />
            D. Commercial information - Transaction information, purchase
            history, financial details, and payment information - COLLECTED
            <br />
            E. Biometric information - Fingerprints and voiceprints - NOT
            COLLECTED
            <br />
            F. Internet or other similar network activity - Browsing history,
            search history, online behavior, interest data, and interactions
            with our and other websites, applications, systems, and
            advertisements - NOT COLLECTED
            <br />
            G. Geolocation data - Device location - NOT COLLECTED
            <br />
            H. Audio, electronic, visual, thermal, olfactory, or similar
            information - Images and audio, video or call recordings created in
            connection with our business activities - NOT COLLECTED
            <br />
            I. Professional or employment-related information - Business contact
            details in order to provide you our Services at a business level or
            job title, work history, and professional qualifications if you
            apply for a job with us - NOT COLLECTED
            <br />
            J. Education Information - Student records and directory information
            - NOT COLLECTED
            <br />
            K. Inferences drawn from collected personal information - Inferences
            drawn from any of the collected personal information listed above to
            create a profile or summary about, for example, an individual’s
            preferences and characteristics - NOT COLLECTED
            <br />
            L. Sensitive personal Information - NOT COLLECTED
            <br />
            We will use and retain the collected personal information as needed
            to provide the Services or for:
            <br />
            - Category A - As long as the user has an account with us
            <br />
            - Category B - As long as the user has an account with us
            <br />
            - Category D - As long as the user has an account with us
            <br />
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
            <br />
            - Receiving help through our customer support channels;
            <br />
            - Participation in customer surveys or contests; and
            <br />
            - Facilitation in the delivery of our Services and to respond to
            your inquiries.
            <br />
            How do we use and share your personal information?
            <br />
            Learn about how we use your personal information in the section,
            "HOW DO WE PROCESS YOUR INFORMATION?"
            <br />
            We collect and share your personal information through:
            <br />
            - Targeting cookies/Marketing cookies
            <br />
            - Social media cookies
            <br />- Beacons/Pixels/Tags
          </p>
          <br />
          <h6>Will your information be shared with anyone else?</h6>
          <br />
          <p>
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Learn more about how we disclose personal information to in the
            section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
            <br />
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal information.
            <br />
            We have not sold or shared any personal information to third parties
            for a business or commercial purpose in the preceding twelve (12)
            months. We have disclosed the following categories of personal
            information to third parties for a business or commercial purpose in
            the preceding twelve (12) months:
            <br />
            The categories of third parties to whom we disclosed personal
            information for a business or commercial purpose can be found under
            "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
          </p>
          <br />
          <h6>California Residents</h6>
          <br />
          <p>
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law permits our users who are California residents to request
            and obtain from us, once a year and free of charge, information
            about categories of personal information (if any) we disclosed to
            third parties for direct marketing purposes and the names and
            addresses of all third parties with which we shared personal
            information in the immediately preceding calendar year. If you are a
            California resident and would like to make such a request, please
            submit your request in writing to us using the contact information
            provided below.
            <br />
            If you are under 18 years of age, reside in California, and have a
            registered account with the Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g., backups, etc.).
          </p>
          <br />
          <h6>CCPA Privacy Notice</h6>
          <br />
          <p>
            This section applies only to California residents. Under the
            California Consumer Privacy Act (CCPA), you have the rights listed
            below.
            <br />
            The California Code of Regulations defines a "residents" as:
            <br />
            (1) every individual who is in the State of California for other
            than a temporary or transitory purpose and
            <br />
            (2) every individual who is domiciled in the State of California who
            is outside the State of California for a temporary or transitory
            purpose
            <br />
            All other individuals are defined as "non-residents."
            <br />
            If this definition of "resident" applies to you, we must adhere to
            certain rights and obligations regarding your personal information.
            <br />
            Your rights with respect to your personal data
            <br />
            Right to request deletion of the data — Request to delete
            <br />
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation, or any
            processing that may be required to protect against illegal
            activities.
            <br />
            Right to be informed — Request to know
            <br />
            Depending on the circumstances, you have a right to know:
            <br />
            - whether we collect and use your personal information;
            <br />
            - the categories of personal information that we collect;
            <br />
            - the purposes for which the collected personal information is used;
            <br />
            - whether we sell or share personal information to third parties;
            <br />
            - the categories of personal information that we sold, shared, or
            disclosed for a business purpose;
            <br />
            - the categories of third parties to whom the personal information
            was sold, shared, or disclosed for a business purpose;
            <br />
            - the business or commercial purpose for collecting, selling, or
            sharing personal information; and
            <br />
            - the specific pieces of personal information we collected about
            you.
            <br />
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
            <br />
            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
            Rights
            <br />
            We will not discriminate against you if you exercise your privacy
            rights.
            <br />
            Right to Limit Use and Disclosure of Sensitive Personal Information
            <br />
            We do not process consumer's sensitive personal information.
            <br />
            Verification process
            <br />
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g., phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
            <br />
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
            <br />
            Other privacy rights
            <br />
            - You may object to the processing of your personal information.
            <br />
            - You may request correction of your personal data if it is
            incorrect or no longer relevant, or ask to restrict the processing
            of the information.
            <br />
            - You can designate an authorized agent to make a request under the
            CCPA on your behalf. We may deny a request from an authorized agent
            that does not submit proof that they have been validly authorized to
            act on your behalf in accordance with the CCPA.
            <br />
            - You may request to opt out from future selling or sharing of your
            personal information to third parties. Upon receiving an opt-out
            request, we will act upon the request as soon as feasibly possible,
            but no later than fifteen (15) days from the date of the request
            submission.
            <br />
            To exercise these rights, you can contact us by visiting{' '}
            <a href="https://globalbuzzers.com">https://globalbuzzers.com</a>,
            by email at info@globalbuzzers.com, or by referring to the contact
            details at the bottom of this document. If you have a complaint
            about how we handle your data, we would like to hear from you.
          </p>
          <br />
          <h6>Colorado Residents</h6>
          <br />
          <p>
            This section applies only to Colorado residents. Under the Colorado
            Privacy Act (CPA), you have the rights listed below. However, these
            rights are not absolute, and in certain cases, we may decline your
            request as permitted by law.
            <br />
            - Right to be informed whether or not we are processing your
            personal data
            <br />
            - Right to access your personal data
            <br />
            - Right to correct inaccuracies in your personal data
            <br />
            - Right to request deletion of your personal data
            <br />
            - Right to obtain a copy of the personal data you previously shared
            with us
            <br />
            - Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects ("profiling")
            <br />- To submit a request to exercise these rights described
            above, please email info@globalbuzzers.com or visit{' '}
            <a href="https://globalbuzzers.com">https://globalbuzzers.com</a>
            <br />
            If we decline to take action regarding your request and you wish to
            appeal our decision, please email us at info@globalbuzzers.com.
            Within forty-five (45) days of receipt of an appeal, we will inform
            you in writing of any action taken or not taken in response to the
            appeal, including a written explanation of the reasons for the
            decisions.
          </p>
          <br />
          <h6>Connecticut Residents</h6>
          <br />
          <p>
            This section applies only to Connecticut residents. Under the
            Connecticut Data Privacy Act (CTDPA), you have the rights listed
            below. However, these rights are not absolute, and in certain cases,
            we may decline your request as permitted by law.
            <br />
            - Right to be informed whether or not we are processing your
            personal data
            <br />
            - Right to access your personal data
            <br />
            - Right to correct inaccuracies in your personal data
            <br />
            - Right to request deletion of your personal data
            <br />
            - Right to obtain a copy of the personal data you previously shared
            with us
            <br />
            - Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects ("profiling")
            <br />
            To submit a request to exercise these rights described above, please
            email info@globalbuzzers.com or visit{' '}
            <a href="https://globalbuzzers.com">https://globalbuzzers.com</a>
            <br />
            If we decline to take action regarding your request and you wish to
            appeal our decision, please email us at info@globalbuzzers.com.
            Within sixty (60) days of receipt of an appeal, we will inform you
            in writing of any action taken or not taken in response to the
            appeal, including a written explanation of the reasons for the
            decisions.
          </p>
          <br />
          <h6>Utah Residents</h6>
          <br />
          <p>
            This section applies only to Utah residents. Under the Utah Consumer
            Privacy Act (UCPA), you have the rights listed below. However, these
            rights are not absolute, and in certain cases, we may decline your
            request as permitted by law.
            <br />
            - Right to be informed whether or not we are processing your
            personal data
            <br />
            - Right to access your personal data
            <br />
            - Right to request deletion of your personal data
            <br />
            - Right to obtain a copy of the personal data you previously shared
            with us
            <br />
            - Right to opt out of the processing of your personal data if it is
            used for targeted advertising or the sale of personal data
            <br />
            To submit a request to exercise these rights described above, please
            email info@globalbuzzers.com or visit{' '}
            <a href="https://globalbuzzers.com">https://globalbuzzers.com</a>
          </p>
          <br />
          <h6>Virginia Residents</h6>
          <br />
          <p>
            Under the Virginia Consumer Data Protection Act (VCDPA):
            <br />
            "Consumer" means a natural person who is a resident of the
            Commonwealth acting only in an individual or household context. It
            does not include a natural person acting in a commercial or
            employment context.
            <br />
            "Personal data" means any information that is linked or reasonably
            linkable to an identified or identifiable natural person. "Personal
            data" does not include de-identified data or publicly available
            information.
            <br />
            "Sale of personal data" means the exchange of personal data for
            monetary consideration.
            <br />
            If this definition of "consumer" applies to you, we must adhere to
            certain rights and obligations regarding your personal data.
            <br />
            Your rights with respect to your personal data
            <br />
            - Right to be informed whether or not we are processing your
            personal data
            <br />
            - Right to access your personal data
            <br />
            - Right to correct inaccuracies in your personal data
            <br />
            - Right to request deletion of your personal data
            <br />
            - Right to obtain a copy of the personal data you previously shared
            with us
            <br />
            - Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects ("profiling")
            <br />
            Exercise your rights provided under the Virginia VCDPA
            <br />
            You may contact us by email at info@globalbuzzers.com or visit{' '}
            <a href="https://globalbuzzers.com">https://globalbuzzers.com</a>
            <br />
            If you are using an authorized agent to exercise your rights, we may
            deny a request if the authorized agent does not submit proof that
            they have been validly authorized to act on your behalf.
            <br />
            Verification process
            <br />
            We may request that you provide additional information reasonably
            necessary to verify you and your consumer's request. If you submit
            the request through an authorized agent, we may need to collect
            additional information to verify your identity before processing
            your request.
            <br />
            Upon receiving your request, we will respond without undue delay,
            but in all cases, within forty-five (45) days of receipt. The
            response period may be extended once by forty-five (45) additional
            days when reasonably necessary. We will inform you of any such
            extension within the initial 45-day response period, together with
            the reason for the extension.
            <br />
            Right to appeal
            <br />
            If we decline to take action regarding your request, we will inform
            you of our decision and reasoning behind it. If you wish to appeal
            our decision, please email us at info@globalbuzzers.com. Within
            sixty (60) days of receipt of an appeal, we will inform you in
            writing of any action taken or not taken in response to the appeal,
            including a written explanation of the reasons for the decisions. If
            your appeal is denied, you may contact the Attorney General to
            submit a complaint.
          </p>
          <br />
          <h5>12. Do we make updates to this notice?</h5>
          <br />
          <p>
            In Short: Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
            <br />
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
          <br />
          <h5>13. How can you contact us about this notice?</h5>
          <br />
          <p>
            If you have questions or comments about this notice, you may email
            us at info@globalbuzzers.com.
          </p>
          <br />
          <h5>
            14. How can you review, update, or delete the data we collect from
            you?
          </h5>
          <br />
          <p>
            -Based on the applicable laws of your country, you may have the
            right to request access to the personal information we collect from
            you, change that information, or delete it. To request to review,
            update, or delete your personal information, please visit:{' '}
            <a href="https://globalbuzzers.com">https://globalbuzzers.com</a>
          </p>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default PrivacyModal;
