const Navebar = () => {
  const scroll = (elementId: string) => {
    let elem: any = document.getElementById(elementId);
    elem.scrollIntoView();
  };
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light sticky-top"
      style={{
        boxShadow: '0px 5px 8px rgba(0,0,0,0.08)',
        backgroundColor: '#fff',
      }}
    >
      <div className="container">
        <button
          className="navbar-brand"
          style={{
            width: '250px',
            border: 'none',
            backgroundColor: 'transparent',
          }}
          onClick={() => scroll('header')}
        >
          <img src="/globalbuzzersLogo-Horizontal.png" alt="" />
        </button>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item" style={{ marginRight: '20px' }}>
              <button
                className="nav-link nav-buttons"
                onClick={() => scroll('top')}
              >
                Home
              </button>
            </li>
            <li className="nav-item" style={{ marginRight: '20px' }}>
              <button
                className="nav-link nav-buttons"
                onClick={() => scroll('about')}
              >
                About
              </button>
            </li>
            <li className="nav-item" style={{ marginRight: '20px' }}>
              <button
                className="nav-link nav-buttons"
                onClick={() => scroll('services')}
              >
                Services
              </button>
            </li>
            <li className="nav-item" style={{ marginRight: '20px' }}>
              <button
                className="nav-link nav-buttons"
                onClick={() => scroll('portfolio')}
              >
                Testimonials
              </button>
            </li>
            <li className="nav-item" style={{ marginRight: '20px' }}>
              <button
                className="nav-link nav-buttons"
                onClick={() => scroll('blog')}
              >
                Blog
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link nav-buttons"
                onClick={() => scroll('contact')}
              >
                Contact
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navebar;
