const Header = () => {
  const emailAddress = 'info@globalbuzzers.com';
  const openInNewTab = (url: string) => {
    window.open(url, '_blank')?.focus();
  };
  return (
    <div className="pre-header" id="header">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-8 col-7">
            <ul className="info">
              <li>
                <a
                  href={`mailto:${emailAddress}?subject=SendMail&body=Description`}
                >
                  <i className="fa fa-envelope"></i>
                  <span className="hide-text">{emailAddress}</span>
                </a>
              </li>
              <li>
                <button
                  onClick={() => openInNewTab('https://wa.me/16695775608')}
                >
                  <i className="fa fa-whatsapp"></i>
                  <span className="hide-text">+1 (669) 577-5608</span>
                </button>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-sm-4 col-5">
            {/* <ul className="social-media">
              <li>
                <button
                  onClick={() =>
                    openInNewTab('https://www.instagram.com/ThinqPr/')
                  }
                >
                  <i className="fa fa-instagram"></i>
                </button>
              </li>
              <li>
                <button
                  onClick={() =>
                    openInNewTab(
                      'https://x.com/ThinqPr?t=33rB8iI8hHiVbakt6yrOGA&s=08'
                    )
                  }
                >
                  <i className="fa fa-twitter"></i>
                </button>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
