export const AuthorReviews = [
  {
    id: 1,
    author: 'Miriam Toews',
    authorImageUrl: 'assets/images/miriam-toews.webp',
    book: 'Fight Night',
    bookFullName:
      "Fight Night: 'A Gem: Humour and Hope in the Face of Suffering' Observer Miriam Toews",
    shortDescription:
      'I loved working with my publicist and I am really happy with my choice of going with Globalbuzzers PR to help push Fight Night out there a bit more. The positivity about my novel and the exciting opportunities they secured for me offered the chance for me to talk and write about my book further. It’s created a buzz around Fight Night and I’m starting to see the impact of that on my sales. I absolutely recommend Globalbuzzers PR and will be sure to come back to them when my next book is ready.',
  },
  {
    id: 2,
    author: 'Samantha San Miguel',
    authorImageUrl: 'assets/images/sam-gower.jpeg',
    book: 'Spineless',
    bookFullName: 'Spineless',
    shortDescription:
      'In the four weeks that Globalbuzzers PR represented me I obtained several radio interviews, including Woman’s Hour and BBC Radio London, a major newspaper feature and more. The Globalbuzzers PR team were thorough, professional and friendly. They produced a powerful press release and I received weekly reports on the progress of the campaign. They carefully worked with me on my ideas, while guiding me to the best avenues for publicising my particular book. It was a really good investment.',
  },
  {
    id: 3,
    author: 'Jonathan Franzen',
    authorImageUrl: 'assets/images/Jonathan.jpg',
    book: 'Cross Roads',
    bookFullName: 'Cross Roads',
    shortDescription:
      'I am very pleased with the PR campaign that Globalbuzzers PR team ran for the US launch of my book. They crafted a few different angles to pitch the media which resulted in coverage on iTV, in The Guardian and The New York Times online and soon, Granta magazine. As a result of their efforts, my book shot to #1 in multiple categories on Amazon with several high quality book reviews, too. I felt very well looked after and I look forward to working with ContentPR PR again in the near future.',
  },
  {
    id: 4,
    author: 'Alexandra Franzen',
    authorImageUrl: 'assets/images/alexandra.jpg',
    book: 'The Checklist Book',
    bookFullName: 'The Checklist Book',
    shortDescription:
      'Top notch with wide experience, great resources and strategy, and impeccable, professional –as well as genuinely caring- attitude. I am very happy to have hired Globalbuzzers PR, not only due to the results they did manage to obtain in my case but also due to the way they engaged and accompanied me every step of the way. I could not recommend them more.',
  },
  {
    id: 5,
    author: 'Colette Sewall',
    authorImageUrl: 'assets/images/colette.jpg',
    book: 'Kiki MacAdoo and the Graveyard Ballerinas',
    bookFullName: 'Kiki MacAdoo and the Graveyard Ballerinas',
    shortDescription:
      'Over the month that I have been working with Globalbuzzers PR I have been impressed by the way they have so widely distributed my book, bringing it to the attention of a great range of media, including national and local press and radio, and specific outlets with an interest in the subject and period. The uptake over this period has been impressive. I could not possibly have achieved anything approaching this level of coverage without their commitment, for which I am very grateful. They have been a pleasure to work with.',
  },
  {
    id: 6,
    author: 'Nicola Edwards',
    authorImageUrl: 'assets/images/nicola.jpg',
    book: "You're My Little Lucky Charm",
    bookFullName: "You're My Little Lucky Charm",
    shortDescription: `Globalbuzzers PR created a tailor-made campaign for my children's book "You're My Little Lucky Charm", involving  podcasts, magazines & newspapers. This was to fit neatly alongside the other promotion my publisher, Silver Dolphin Books, was actively engaged in. They worked tirelessly to publicise 'You're My Little Lucky Charm' , pitching to a large range of select targets, and securing coverage by numerous newspapers, local radio and bookstores.`,
  },
  {
    id: 7,
    author: 'Lisa Scottoline',
    publisher: 'Penguin Random House',
    authorImageUrl: 'assets/images/Penguin-Random-House.jpg',
    book: 'Loyalty',
    bookFullName: 'Loyalty',
    shortDescription:
      "We met Brendan Miller (Globalbuzzers PR) at a social event last year – and we're very glad that we did! We had a previous experience of dealing with other well-known book publicists and were extremely disappointed with the lack of personal contact and measurable outcomes. But Globalbuzzers PR seemed to represent something very different. Their team took great care to keep in regular touch, made sure that potential reviewers were appropriate, and more than met our expectations so far as outcomes were concerned. Excellent value!",
  },
  {
    id: 8,
    author: 'Helen Elaine Lee',
    publisher: 'Simon & Schuster',
    authorImageUrl: 'assets/images/Simon-and-Schuster.png',
    book: 'Pomegranate: A Novel',
    bookFullName: 'Pomegranate: A Novel',
    shortDescription: `You feel encouraged and supported from the very beginning. The publicity campaign is focussed on what you want to achieve and realistic in scope. The approach is professional, efficient and reassuringly friendly. You are not just a client and may continue to receive contacts and ideas even after the contract has ended. We know where to turn next time.`,
  },
  {
    id: 9,
    author: 'Monica McAlpine',
    authorImageUrl: 'assets/images/monica.jpg',
    book: 'Winter Bridge',
    bookFullName: 'Winter Bridge',
    shortDescription: `Globalbuzzers PR have been an enormous help in gaining publicity for my book Winter Bridge. With their knowledge and expertise I was able to obtain TV and national newspaper coverage. Having them as my publicist exceeded my wildest expectations. Thanks Globalbuzzers PR`,
  },
];
