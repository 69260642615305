interface FooterProps {
  onPrivacyClick: () => void;
  onTermsConditionsClick: () => void;
}

const Footer = (props: FooterProps) => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <p>Copyright © 2017 GlobalBuzzers PR Ltd. All Rights Reserved.</p>
          </div>
          <div className="col-lg-5">
            <div className="row policies">
              <div className="col-lg-5">{/* <button>FAQs</button> */}</div>
              <div className="col-lg-3 privacy">
                <button onClick={props.onPrivacyClick}>Privacy</button>
              </div>
              <div className="col-lg-4" style={{ textAlign: 'center' }}>
                <button onClick={props.onTermsConditionsClick}>Terms</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
